import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		$(".header-social-icon .btn, .cta-bl .btn").click(function () {
			$(".search-col").slideToggle();
		});

	},

	methods: {

		navbarToggle: function () {
			$('.main-menu').slideToggle();
			$(".navbar-v1").toggleClass('openMenu');
			$(".dropdown-toggle").click(function () {
				var target = $(this).parent().children(".submenu");
				$(target).slideToggle();
				$(this).parent().siblings().children(".submenu").slideUp();
				$(this).toggleClass('slidedown');
				$(this).parent().siblings().children().removeClass("slidedown");
			});

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};