import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			selectedImageIndex: undefined,
			facilityid: undefined,
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var unitgroup = this.getUnitGroupId(parseInt(this.$el.dataset.facilityid, 10));
		console.log("unitgroupid is==>" + unitgroup);

		var req = {
			fields: this.resultFields,
			filter: {
				unitgroupid: unitgroup
			},

			sorting: 'random'
		};

		this.doSearchNow(req);

	},


	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		doSearchNow: function (req) {
			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;
				});

		},
		getUnitGroupId: function (id) {
			if (id == '187299') {
				return 43823;
			}
			if (id == '187298') {
				return 43825;
			}
			if (id == '187297') {
				return 43824;
			}
			if (id == '187300') {
				return 43822;
			}
			if (id == '196354') {
				return 48150;
			}
			if (id == '196356') {
				return 48230;
			}
			return null;

		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
	},
	filters: {
		formatfeedback: function (value) {
			if (!value) return '';
			if (Number.isInteger(value)) return value.toFixed();
			return (value.toFixed(1).replace('.', ','));
		}
	}

};