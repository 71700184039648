import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			units2: [],
		};
	},
	props: {
		hidetab: String,
		gotAllUnits: String
	},

	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var beds = 2;
		var immensee;
		let el = document.querySelector("[data-beds]");
		let el2 = document.querySelector("[data-immensee]");
		if (el) {
			beds = parseInt(el.getAttribute('data-beds'), 10);
		} else {
			console.log('element is not found..................');
		}
		if (el2) {
			immensee = parseInt(el2.getAttribute('data-immensee'));
			//console.log('immensee:::', immensee);
		} else {
			//console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
		}

		//console.log('before region id..................');
		let e2 = document.querySelector("[data-region-id]");
		//console.log('region id :' + e2.getAttribute('data-region-id'));
		var regionId;
		if (e2) {
			regionId = parseInt(e2.getAttribute('data-region-id'));

			//console.log('region id int:' + regionId);
		} else {
			console.log('element is not found..................');
		}
		let e3 = document.querySelector("[data-groupid]");
		//console.log('group id :' + e3.getAttribute('data-groupid'));
		var providedgroup;
		if (e3) {
			providedgroup = parseInt(e2.getAttribute('data-groupid'));
			//console.log('providedgroup:' + providedgroup);
		} else {
			console.log('element is not found..................');
		}
		var req;
		var req2;
		var isHideTab = true;
		if (immensee === 1) {
			req = {
				fields: this.resultFields,
				filter: {
					'p_15603': 1

				},
				max: 6,
				sorting: 'random'
			};
		} else {
			req = {
				fields: this.resultFields,
				filter: {
					'region.id': regionId
				},
				max: 6,
				sorting: 'random'
			};
			if (providedgroup) {
				req2 = {
					fields: this.resultFields,
					filter: {
						unitgroupid: providedgroup
					},
					max: 6,
					sorting: 'random'
				};
			}
		}


		doReq('searchUnits', req)
			.then((res) => {
				//console.log('got unit1.............');
				this.units = res.units;
			});

		if (providedgroup && req2) {
			isHideTab = false;
			doReq('searchUnits', req2)
				.then((res) => {
					//console.log('got unit2.............');
					this.units2 = res.units;
				});
		}


		//console.log('isHideTab:::' + isHideTab);
		if (isHideTab) {
			this.hidetab = 'true';
		}

	},
	updated: function () {

		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
		if (this.hidetab === 'true') {
			$("#tabs").hide();
			$("#similarsection1").hide();
			$("#similarsection2").show();
		} else {
			$("#similarsection2").hide();
			$("#similarsection1").show();
		}

		if (this.units && this.units.length > 0) {
			this.applySlider2();
		}
		if (this.units2 && this.units2.length > 0) {
			this.applySlider();
		}


	},
	methods: {
		toogleTabs: function (value) {
			var other = null;
			if (value == 1) {
				other = 2;
			} else if (value == 2) {
				other = 1;
			}

			$('.tab').removeClass('active');

			$('.tab' + value).addClass('active');

			$('#similarsection' + value).css({
				'display': 'block',
				'z-index': '3',
				'visibility': 'visible'
			});


			$('#similarsection' + other).css({
				'display': 'none',
				'z-index': '0',
				'visibility': 'hidden'
			});
		},

		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},


		applySlider: function () {
			//console.log('applying slider');
			$(".owl-carousel.similar-units1").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		},

		applySlider2: function () {
			//console.log('applying slider 2');
			$(".owl-carousel.similar-units2").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};